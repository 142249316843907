<!--
 * @Author: your name
 * @Date: 2021-04-30 16:17:35
 * @LastEditTime: 2021-08-31 17:57:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \练习\src\views\home\home.vue
-->
<template>
  <div>
    <div class="home">
      <div class="top">
        <img src="../../assets/image/1-14.png">
        <wallet :connect="connect" :claim="claim" />
        <div class="tp-text" v-if="goubi">
          <p> {{ $t("user.jiayuan") }}</p>

          <button class="introducebutton" @click="open">{{ $t("user.introduce") }}</button>


        </div>
        <div class="cawu" v-if="cawu">
          <p>{{$t("user.Wallet Balance")}}：{{$store.state.balance}}</p>
          <!--            <p>{{$t("user.Accumulated Dividends")}}：987</p>-->
          <p>{{$t("user.Not Achieved")}}：{{$store.state.receiveDisabled ? '0' : $store.state.receive}}</p>
          <template v-if="$store.state.account">
            <div @click="claimReceive" :class="{disabled: $store.state.receiveDisabled}">{{$t("user.Achieved")}}</div>
          </template>
          <template v-else>
            <div @click="connectWallet">Connect Wallet</div>
          </template>
        </div>
        <div class="introducediv" v-if="daibi">
          <p>{{ $t("user.jiayuan") }}</p>
          <p>{{ $t("user.Antibody") }}</p>
          <p>{{ $t("user.Start community") }}</p>
          <p>{{ $t("user.Economic") }}</p>
          <p>{{ $t("user.Start trade") }}</p>
          <p>{{ $t("user.Start diversified") }}</p>
          <p>{{ $t("user.Upgrade battlefields") }}</p>
          <p class="zuipi">{{ $t("user.Antibody constructs") }}</p>

          <button class="guanbi" @click="openfalse">{{ $t("user.close") }}</button>

        </div>
      </div>
      <div class="title">
        <div>
          <div class="top-left">
            <div style="margin-right:15px">
              <img src="../../assets/image/1-4.png" style="width:30px">
            </div>
            <div class="logoname">
              {{ $t("user.abc") }}
            </div>

          </div>
        </div>
        <div class="top-center">
          <div class="name"> {{ $t("user.name") }}</div>
          <div>
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ $t("user.battlefield") }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>{{ $t("user.Battle") }}</el-dropdown-item>
                <el-dropdown-item>{{ $t("user.Teamfight") }}</el-dropdown-item>
                <el-dropdown-item>{{ $t("user.Battlefield Treasure Hunt") }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>{{ $t("user.NFT transactions") }}</div>
          <div>{{ $t("user.Biochemical Research Office") }}</div>
          <div>{{ $t("user.Combat Research Office") }}</div>
          <div @click="cawu = !cawu">{{ $t("user.My Finance") }}</div>
        </div>

        <div style="width:10%">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ $t("user.Language") }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" v-on:change="indexSelect($event)">
              <el-dropdown-item @click.native="zh"> {{ $t("user.chinese") }}</el-dropdown-item>
              <el-dropdown-item @click.native="en">{{ $t("user.english") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="login-text" @click="connectWallet" v-if="!$store.state.account"> Connect Wallet</div>
        <div class="wallet-text" @click="connectWallet" v-else> {{ cpd_accountText }}</div>
      </div>

      <div class="scene">

        <div class="soldier"></div>
        <div class="address">
          <span style="margin-left:10px">
            <b>{{ $t("user.Contract address") }}:{{coplytext}}</b>
          </span>
          <span class="coply">
            <b v-clipboard:copy="coplytext" v-clipboard:success="onCopy" >{{$t("user.copy")}}</b>
          </span>
          <span class="buy">
            <b>
              <a href="https://pancakeswap.finance/swap?outputCurrency=0x2f389c548062f50257a17e6c20e07935d64a917c">
                {{$t("user.buy")}}
              </a>
            </b>
          </span>
          <span class="t"><a href="https://t.me/AntibodyCoin"><img src="../../assets/image/t.png" /></a></span>
      </div>

        <div class="introduce">

            <div class="introduce-text">{{ $t("user.battlefield") }}</div>
            <div class="introduce-text-cheng">{{ $t("user.Intheaffluent") }}</div>
        </div>

        <div class="star"></div>
        <div class="scene-img">
          <div class="img1"></div>
          <div class="img2"></div>
          <div class="img3"></div>

        </div>
        <div class="wavezindex0"></div>
      </div>
      <div class="wave">

        <div class="wavezindex1"></div>
        <div class="market">
            <div class="market-text">{{ $t("user.Market Introduction") }}</div>
            <div class="market-text-cheng">{{ $t("user.Startyourfighting") }}</div>
        </div>


        <div class="Scenario3"></div>
        <div class="Scenario4">
          <div class="biology">
            <div class="biology-text">{{ $t("user.Biochemistry Laboratory") }}</div>
            <div class="biology-text-cheng">{{ $t("user.Update technology") }}</div>
          </div>
        </div>
        <div class="zzs"></div>
      </div>
      <div class="bottom">
        <div class="guard"></div>
        <div class="guard-text">
          <p> {{ $t("user.War Laboratory") }}:</p>
          <p>{{ $t("user.Break the") }}</p>
        </div>
        <div class="bottom-text">
          <p>{{ $t("user.abcthe") }}</p>
          <p>{{ $t("user.Once again") }}</p>
          <p>{{$t("user.We hope")}}</p>
         </div>
      </div>
    </div>

  </div>
</template>


<script>
  import wallet from "../../components/wallet";
  export default {
    components: {
      wallet
    },
    data() {
      return {
        daibi: false,
        goubi: true,
        cawu:false,
        coplytext:'0x2f389c548062f50257a17e6c20e07935d64a917c',
        connect: false,
        claim: false
      }
    },
    computed: {
      cpd_accountText() {
        const account = this.$store.state.account
        if (!account) {
          return ''
        }
        const len = account.length
        const arr = account.split('')
        arr.splice(4, len - 8, '....')
        return arr.join('')
      }
    },
    created() {
      // console.log(this.$store.state.zh,888888)
    },
    methods: {
      zh() {

        this.$i18n.locale = 'zh'
        //
      },
      en() {
        this.$i18n.locale = 'en'
      },
      coply(){

      },
      open() {
        this.daibi = true
        this.goubi = false
      },
      openfalse() {
        this.daibi = false
        this.goubi = true
      },
      onCopy(){
        this.$message({
          message: 'success',
          type: 'success'
        });
      },
      connectWallet() {
        this.connect = true
      },
      claimReceive() {
        if (this.$store.state.receiveDisabled) {
          this.cawu = false
          return
        }
        this.claim = true
        this.cawu = false
      }
    }
  }
</script>

<style scoped>
  .zuipi{
    padding-bottom: 80px;
  }
  .el-dropdown-link {
    font-size: 10px;
  }
  .bottom-text{
    position: absolute;
    bottom: 50px;
    color: #fff;
   left: 28%;
   width: 50%;
   text-align: center;

  }
  .bottom-text p{
    margin-top: 6px;
  }

.cawu{
  width: auto;
  height: 146px;
  background: #fff;
  z-index: 8;
  position: absolute;
  top: 60px;
  left: 65%;
  border-radius: 5px;
  text-align:left;
  color: #0F1140;
  padding-right: 16px;
  padding-left: 16px;

}
.cawu p{
  margin-top: 20px;
}
.cawu div{
  margin-top: 20px;
  background: #053CA5;
  color: #fff;
  height: 33px;
  white-space: nowrap;
  text-align: center;
  line-height: 33px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 0 16px;
}
.cawu div.disabled {
  background: #999;
}
  .guanbi {
    width: 120px;
    height: 40px;
    /* background: #FFFFFF; */
    box-shadow: 0px 0px 10px 0px #FFFFFF;
    border-radius: 10px;
    /* opacity: 0.2; */
    position: absolute;
    bottom: 5px;

    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.2);

    border-radius: 5px;
    color: #fff;

  }
  .introduce-text {
    position: absolute;
    left: 25%;
    top: 20px;
    width: 100%;
    z-index: 7;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }

  .introduce-text-cheng {
    position: absolute;
    left: 22%;
    top: 46%;

    width: 100%;
    color: #fff;
    text-align: center;

    font-size: 16px;
  }
  .img1 {
    width: 32%;
    height: 100%;

    background-image: url("../../assets/image/1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .img2 {
    width: 30%;
    height: 100%;

    background-image: url("../../assets/image/2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .wave {
    width: 100%;
    height: 100%;
    /* background: rebeccapurple; */
    position: relative;
    margin-top: -100px;
    background-image: url("../../assets/image/1-11.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .img3 {
    width: 30%;
    height: 100%;

    background-image: url("../../assets/image/3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .biology-text {
    position: absolute;
    right:19%;
    top: 15px;

     width: 100px;
    font-size: 17px;
    text-align: center;
  }

  .biology-text-cheng {
    position: absolute;
    right: -7%;
    top: 70px;
    width: 70%;

    font-size: 16px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1476px) {

    .name {
      font-size: 16px;
    }

    .top-center {
      width: 75%;
      height: 50px;

      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff;
      margin-left: 100px;
      font-size: 14px;
    }

    .img1 {
    width: 32%;
    height: 100%;

    background-image: url("../../assets/image/1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .img2 {
    width: 30%;
    height: 100%;

    background-image: url("../../assets/image/2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }



  .img3 {
    width: 30%;
    height: 100%;

    background-image: url("../../assets/image/3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  /* .biology-text {
    position: absolute;
    right: 9.5%;
    top: 15px;

    font-size: 15px;
  } */
  .cawu{
  width: auto;
  height: 146px;


}
  }

  @media screen and (min-width: 1024px) and (max-width: 1280px) {

    .name {
      font-size: 13px;
    }

    .top-center {
      width: 75%;
      height: 50px;

      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff;
      margin-left: 100px;
      font-size: 14px;

    }
    .img1 {
    width: 32%;
    height: 95%;

    background-image: url("../../assets/image/1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .img2 {
    width: 30%;
    height: 95%;

    background-image: url("../../assets/image/2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }



  .img3 {
    width: 30%;
    height: 95%;

    background-image: url("../../assets/image/3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  /* .biology-text {
    position: absolute;
    right: 10%;
    top: 15px;

    font-size: 14px;
  } */
  .cawu{
  width: 135px;
  height: 175px;


}
  }

  @media screen and (max-width: 1024px) {
    .name {
      font-size: 22px;
    }
  }

  .introducediv {
    z-index: 6;
    opacity: 0.9;
    position: absolute;
    left: 25%;
    top: 100px;
    width: 45%;
    /* height: 460px; */
    background: #70312A;
    border-radius: 15px;
    text-align: left;
    color: #fff;
    font-size: 13px;
  }

  .introducediv p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px
  }

  .address {
    width: 38%;
    margin-left:34%;
    margin-right: auto;
    height: 35px;
    margin-bottom: 20px;
    background:#FFFFFF;
   margin-top: 200px;
    line-height: 35px;
    position: relative;
    border-radius: 5px;
  }
  .address .t {
    position: absolute;
    right: -62px;
    top: 1px;
    width: 34px;
    display: flex;
    height: 34px;
  }
  .address .t img {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .coply {
    position: absolute;
    right: 80px;
    color: rgb(34, 51, 211);
  }

  .buy {
    background: rgb(8, 8, 150);
    height: 100%;
    width: 60px;
    position: absolute;
    right: -1px;
    top: 0;
    color: #fff;
    text-align: center;
    border-radius: 0px 5px 5px 0px;
  }
  a { color: rgb(250, 250, 250); }
  .introducebutton {
    width: 70px;
    margin-top: 10px;
    background: #4e465a;
    height: 30px;
    color: #fff;
    border-radius: 5px;
  }



  .market-text {

    width: 17%;
    color: #fff;
    text-align: center;
    z-index: 6;

    margin-left: 17%;

    margin-top: 5px;
    font-size: 18px;
  }

  .market-text-cheng {
    position: absolute;

    top: 15%;
    z-index: 6;
    text-align:left;
    width: 50%;

    color: #fff;
    font-size: 16px;
  }


  .tp-text {
    position: absolute;
    z-index: 1;
    color: #fff;
    text-align: center;
    width: 25%;
    height: 100px;
    font-size: 13px;
    margin: auto;
    top: -220px;
    left: 100px;
    right: 0;
    bottom: 0;
  }

  .tp-text>p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;

    margin-top: 38px;
  }

  /deep/.el-dropdown {
    display: inline-block;
    position: relative;
    color: #fff;
    font-size: 14px;
    border: none;
    outline: none;
  }

  .guard {
    width: 68%;
    height: 230px;
    position: absolute;
    right: 0px;
    bottom: 130px;
    background-image: url("../../assets/image/1-3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .biology {
    width: 30%;
    height: 150px;

    position: relative;
    margin-left: 55%;
    margin-top: 20%;
    background-image: url("../../assets/image/1-13.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .guard-text {
    width: 25%;
    height: 170px;
    /* background: #000; */
    color: #fff;

    position: absolute;
    right: 330px;
    font-size: 15px;
    bottom: 350px;


  }

  .zzs {
    width: 30%;
    height: 220px;
    position: absolute;
    top: 1640px;
    right: 290px;
    z-index: 7;
    background-image: url("../../assets/image/1-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .bottom {
    width: 100%;
    height: 620px;
    bottom: 0px;
    position: relative;
    margin-top: -200px;
    z-index: 6;
    left: 0;
    background-image: url("../../assets/image/1-9.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* background: linear-gradient(270deg, rgba(34, 39, 116, 0) 0%, rgba(33, 36, 111, 0.53) 10%, #1E2269 20%, #121447 76%, rgba(17, 19, 68, 0.61) 88%, rgba(15, 17, 64, 0) 100%);
opacity: 0.34; */
  }

  .introduce {
    width: 48%;
    height: 150px;

    /* background: #fff; */
    position: relative;
    margin-left: 260px;
    margin-top: 1px;
    /* left: 300px;
   top: 230px; */
    background-image: url("../../assets/image/1-10.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .market {
    width: 50%;
    height: 450px;
    position: absolute;
    top: -30px;
    left: 40%;
    z-index: 6;
    background-image: url("../../assets/image/1-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .Scenario3 {
    width: 100%;
    height: 750px;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 5;
    background-image: url("../../assets/image/1-6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .Scenario4 {
    width: 100%;
    height: 900px;
    position: absolute;
    top: 900px;
    left: 0;
    z-index: 5;
    background-image: url("../../assets/image/1-8.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .scene-img {
    width: 80%;
    height: 300px;

    position: absolute;
    right: 150px;
    top: 510px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }



  .star {
    width: 18%;
    height: 350px;
    /* background: #fff; */
    position: absolute;
    right: 100px;
    opacity: 0.1;
    top: 70px;
    background-image: url("../../assets/image/4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .soldier {
    width: 18%;
    height: 350px;

    position: absolute;
    left: 0;
    top: 230px;
    background-image: url("../../assets/image/0.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .scene img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 820px;


  }

  .wavezindex1 {
    width: 100%;
    height: 1900px;
    /* background: rebeccapurple; */
    position: relative;
    margin-top: -130px;
    background-image: url("../../assets/image/1-12.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 2;
  }

  .wavezindex0 {
    width: 95%;
    height: 300px;

    bottom: 0px;
    left: 40px;
    right: 50px;
    position: absolute;
    background-image: url("../../assets/image/1-7.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 4;
  }

  .scene {
    width: 100%;
    height: 1200px;
    /* background: rebeccapurple; */
    position: relative;
    margin-top: -200px;
    background-image: url("../../assets/image/1-5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .login-text {
    /*width: 98px;*/
    height: 30px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    border: 1px solid red;
    border-radius: 50px;
    padding: 0 16px;
    white-space: nowrap;
  }

  .wallet-text {
    /*width: 98px;*/
    height: 30px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    border: 1px solid red;
    border-radius: 50px;
    white-space: nowrap;
    padding: 0 16px;
  }

  .top-left {
    width: 90px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    color: #fff;
  }

  .top-center {
    width: 85%;
    height: 50px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    margin-left: 100px;
    font-size: 10px;
  }

  .top-center div {
    color: whitesmoke;
    padding-right: 5px;
    border-right: 2px solid #0F1140;
  }

  .home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image:url("../../assets/image/柔光模式0.5透明度.png");
    background-size: 100% 100%;
    background-repeat: no-repeat; */
  }

  .top img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: -1;
  }

  .top {
    position: relative;

  }

  .title {
    width: 80%;
    height: 50px;

    z-index: 1;
    position: absolute;
    top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
